import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { WEB_TITLE } from 'src/constants/layout';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';
import WidgetIconVote from '../sections/section-event/event01/components/widget/widget-icon-vote';

const NavMobile = dynamic(import('src/components/layouts/nav-mobile'));
const NavDesktop = dynamic(import('src/components/layouts/nav-desktop'));
const MediaQuery = dynamic(import('react-responsive'));
const SearchBox = dynamic(import('src/components/search-box'));
const WidgetFollow = dynamic(import('src/components/widgets/widget-follow'));
const WidgetLogin = dynamic(import('src/services/member-auth/components/widget-login'));

const Header = ({ data, sessionSevId }) => {
  const router = useRouter();
  return (
    <>
      <HeaderComponent>
        <div className='navbar-main' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
          <div className='container'>
            <div className='header-vertical-align-containers'>
              <div className='order-2'>
                <MediaQuery maxWidth={1024}>
                  <div className='nav-mobile'>
                    <NavMobile data={data} />
                    <SearchBox />
                    <WidgetLogin sessionSevId={sessionSevId} />
                  </div>
                </MediaQuery>
              </div>
              <div className='header-position-left'>
                {router.asPath === '/' ? (
                  <>
                    <h1>
                      <a
                        href='/'
                        aria-label='logo'
                        title='logo'
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK,
                            router: router?.pathname,
                            section: 'menubar',
                            data: {
                              title: 'logo',
                              heading: 'logo'
                            }
                          })
                        }
                      >
                        <span>{WEB_TITLE}</span>
                        <img
                          className='logo-img'
                          src={`${APP_IMG}/static/logo-black.png`}
                          loading='lazy'
                          alt='logo-heading'
                          title='logo-heading'
                          width='300'
                          height='58'
                        />
                        <WidgetIconVote />
                      </a>
                    </h1>
                  </>
                ) : (
                  <a
                    href='/'
                    aria-label='logo'
                    title='logo'
                    //! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK,
                        router: router?.pathname,
                        section: 'menubar',
                        data: {
                          title: 'logo',
                          heading: 'logo'
                        }
                      })
                    }
                  >
                    <img
                      className='logo-img'
                      src={`${APP_IMG}/static/logo-black.png`}
                      loading='lazy'
                      alt='logo-heading'
                      title='logo-heading'
                      width='300'
                      height='58'
                    />
                    <WidgetIconVote />
                  </a>
                )}
              </div>
              <WidgetFollow
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'follow',
                  position: 'on:menubar'
                }}
              />
            </div>
          </div>
        </div>
        <MenuArea>
          <MediaQuery minWidth={1025}>
            <div className='menu-area' style={{ borderBottom: SHOW_COMMEMORATE ? '1px solid  gray' : '1px solid #ffe600' }}>
              <div className='container d-flex space-between align-item'>
                <div className='show-desktop'>
                  <NavDesktop data={data} />
                </div>
                <div className='d-flex'>
                  <SearchBox />
                  <WidgetLogin sessionSevId={sessionSevId} />
                </div>
              </div>
            </div>
          </MediaQuery>
        </MenuArea>
      </HeaderComponent>
    </>
  );
};

const HeaderComponent = styled.header`
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 70;
  display: flex;
  align-items: center;
  position: sticky;
  flex-direction: column;
  top: -120px;
  @media (max-width: 1024px) {
    top: 0;
    flex-direction: unset;
  }

  .navbar-main {
    background-color: #ffe000;
    padding: 30px 0;
    height: 120px;
    width: 100%;
    @media (max-width: 1024px) {
      display: flex;
      align-items: center;
      height: 75px;
    }
  }

  .header-vertical-align-containers {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .widget-follow {
      @media (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 25%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .order-2 {
      @media (min-width: 1025px) {
        display: none;
      }
      @media (max-width: 1024px) {
        width: 25%;
      }
      @media (max-width: 768px) {
        order: 2;
      }
    }
    .header-position-left {
      position: relative;
      /* @media (max-width: 1024px) {
        width: 50%;
      } */
      @media (max-width: 768px) {
        order: 1;
      }
      img {
        width: 100%;
        max-width: 300px;
        height: auto;
      }
      a {
        display: flex;
        align-items: center;
        @media (max-width: 1024px) {
          justify-content: center;
        }
        @media (max-width: 768px) {
          justify-content: flex-start;
        }
      }
      h1,
      h2 {
        margin: 0;
        padding: 0;
        overflow: hidden;
        position: relative;
        span {
          text-indent: 1000em;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          position: absolute;
          width: 100%;
        }
      }
      @media (max-width: 1024px) {
        img {
          max-width: 190px;
        }
      }
    }

    .nav-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1024px) {
        justify-content: flex-start;
      }
      @media (max-width: 768px) {
        justify-content: flex-end;
      }
    }
  }
`;

const MenuArea = styled.div`
  display: block;
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
  .menu-area {
    position: sticky;
    top: 0;
    //border-bottom: 1px solid #ffe600;
    background-color: #fff;
    min-height: 46px;
    height: 100%;
  }
`;

export default Header;
