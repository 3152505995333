export function convertDatasets(data, topic, isValue) {
  const results = [];
  if (isValue) {
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.key?.startsWith(topic)) {
        results.push(data?.[i]?.value);
      }
    }
  } else {
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.topic?.startsWith(topic)) {
        results.push(data?.[i]?.dataSet);
      }
    }
  }
  return isValue ? results[0] : results;
}
