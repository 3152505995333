import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { CONTROL_ELECTION } from 'src/constants';
import { convertDatasets } from 'src/utils/datasets';
import { GET } from 'src/utils/services';
import styled from 'styled-components';

const WidgetIconVote = ({ style = '' }) => {
  if (CONTROL_ELECTION === 'false') return null;
  const [resData, setResData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const resDataSet = await GET('/api/datasets');
      if (!_.isEmpty(resDataSet)) {
        const resData = convertDatasets(resDataSet, 'section-ele66', false);
        setResData({
          item1: convertDatasets(resData?.[0], 'title-herder', true),
          item2: convertDatasets(resData?.[0], 'lock-cate', true)
          // item3: convertDatasets(resData?.[0], 'logo-vote', true)
        });
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {!_.isEmpty(resData?.item1) && (
        <WidgetWrapper className={`${style}`}>
          <a href={`${resData?.item2}`} title={`${resData?.item2}`}>
            {resData?.item1}
          </a>
          {/* <img src={`${resData?.item3}`} className='logo-vote' title={'logo-vote'} alt={'logo-vote'} width='100%' height='100%' /> */}
        </WidgetWrapper>
      )}
    </>
  );
};

export default WidgetIconVote;

const WidgetWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border: 3px solid #000;
  padding: 6px 8px 0px 8px;
  margin-left: 10px;
  a {
    display: block !important;
    white-space: nowrap;
    font-size: 37px;
    font-weight: 800;
    line-height: 1.2;
  }
  @media (max-width: 1024px) {
    border: 2px solid #000;

    padding: 6px 8px 0px 8px;
    a {
      font-size: 25px;
      font-weight: 700;
    }
  }
  @media (max-width: 475px) {
    display: none;
  }

  &.mobile {
    display: none;
    margin-left: 0px;
    a {
      display: block !important;
      white-space: nowrap;
      font-size: 30px;
      font-weight: 700;
    }
    @media (max-width: 475px) {
      display: block;
      a {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .logo-vote {
    position: absolute;
    right: -12px;
    bottom: -8px;
    width: 100% !important;
    max-width: 25px !important;
    height: auto;
    @media (max-width: 475px) {
      max-width: 35px !important;
    }
  }
`;
